import type { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { encode } from 'querystring';
import { DEFAULT_LOCALE } from 'utils/constants';

const Home: NextPage = () => {
  const router = useRouter();

  const queryStr = encode(router.query);

  if (typeof window !== 'undefined') {
    router.replace(`/give${queryStr ? `?${queryStr}` : ''}`);
  }

  return null;
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? DEFAULT_LOCALE)),
    },
  };
};

export default Home;
